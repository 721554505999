/**
 *  Breakpoints
 * - xs, extra-small: 0px
 * - sm, small: 600px
 * - md, medium: 960px
 * - lg, large: 1280px
 * - xl, extra-large: 1920px
 * @look_at https://material-ui.com/customization/breakpoints/
 **/
import React from "react"
// gatsby route
import { navigate } from "gatsby"
// import emailjs sdk
import emailjs from "emailjs-com"
// react-hook-form
import { useForm, Controller } from "react-hook-form"
// matrial-ui methods
import { makeStyles } from "@material-ui/core/styles"
// material-ui components
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
// atoms components
import TextFeild from "@atoms/TextFeild"
import UploadField from "@atoms/UploadField"
import RadioField from "@atoms/RadioField"
import Button from "@atoms/Button"
// callback compnent
import FormSuccess from "@molecules/Form/FormSuccess"

/**
 * @custom_style
 */
const useStyles = makeStyles(theme => ({
  form: {
    marginBottom: 118,
  },
  formRowShift: {
    [theme.breakpoints.up("md")]: {
      width: "65%",
    },
  },
}))

/**
 *
 * @param {*} props
 * @returns
 */
export default function SearchForm(props) {
  const classes = useStyles()

  // dailog state
  const [isOpen, setIsOpen] = React.useState(false)
  /**
   * @close_dailog
   */
  const closeDialog = () => {
    setIsOpen(false)
    navigate(`/`)
  }

  /**
   * @handle_form
   */
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm()

  /**
   * @handel_controll_form
   */
  const onSubmit = data => {
    console.log(data)
    // send email via emailjs
    emailjs
      .send(
        "service_9x4o6ii",
        "template_94s4pxl",
        data,
        "user_LAV2TzWxySvbqtoDwSO4G"
      )
      .then(
        result => {
          setIsOpen(true)
        },
        error => {
          console.log(error.text)
        }
      )
  }

  return (
    <Box className={classes.form}>
      {/**  */}
      <FormSuccess handleClose={closeDialog} isOpen={isOpen} />

      {/* form title */}
      <Box>
        <Typography
          variant="h4"
          style={{
            fontWeight: "bold",
            fontSize: 25,
            margin: " 50px 0",
          }}
          gutterBottom
        >
          اختر معلومات عن السيارة
        </Typography>
      </Box>

      {/* form  */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1}>
          {/* block one */}
          <Grid container spacing={3} className={classes.formRowShift}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              {/**  */}
              <Controller
                name="carNumber"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field }) => (
                  <TextFeild
                    label="ادخل رقم هيكل المركبة"
                    id="car-number-feild"
                    placeholder="000 000"
                    error={errors.carNumber}
                    field={field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              {/**  */}
              <Controller
                name="odometer"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field }) => (
                  <TextFeild
                    label="عداد المسافات"
                    id="odometer-feild"
                    placeholder="عدد المسافات"
                    error={errors.odometer}
                    field={field}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={3}
            className={classes.formRowShift}
            style={{ marginTop: 50, marginBottom: 50 }}
          >
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              {/**  */}
              <Controller
                name="status"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <RadioField
                    label="النوع"
                    error={errors.status}
                    options={[
                      {
                        value: "new",
                        label: "جديد",
                      },
                      {
                        value: "old",
                        label: "مستعمل",
                      },
                    ]}
                    field={field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              {/**  */}
              <Controller
                name="model"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field }) => (
                  <TextFeild
                    id="modal-name-feild"
                    label=" ماركة السيارة"
                    placeholder="+00 000 00 000"
                    error={errors.model}
                    field={field}
                  />
                )}
              />
            </Grid>
          </Grid>
          {/* ** image upload ** */}
          <Grid container style={{ marginTop: 50, marginBottom: 50 }}>
            {/*  image 1 */}
            <Grid item sm={12} md={6} lg={3} xl={3}>
              <Controller
                name="carImageOne"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field }) => (
                  <UploadField
                    id="car-image-one-feild"
                    title="امام"
                    subTitle="جانب سائق"
                    error={errors.carImageOne}
                    setValue={setValue}
                    field={field}
                  />
                )}
              />
            </Grid>

            {/*  image 2 */}
            <Grid item sm={12} md={6} lg={3} xl={3}>
              <Controller
                name="carImageTwo"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field }) => (
                  <UploadField
                    id="car-image-two-feild"
                    title="خلفي"
                    subTitle="جانب سائق "
                    setValue={setValue}
                    error={errors.carImageTwo}
                    field={field}
                  />
                )}
              />
            </Grid>

            {/*  image 3 */}
            <Grid item sm={12} md={6} lg={3} xl={3}>
              <Controller
                name="carImageThree"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field }) => (
                  <UploadField
                    id="car-image-three-feild"
                    title="امام"
                    subTitle="جانب الراكب"
                    setValue={setValue}
                    error={errors.carImageThree}
                    field={field}
                  />
                )}
              />
            </Grid>

            {/*  image 4 */}
            <Grid item sm={12} md={6} lg={3} xl={3}>
              <Controller
                name="carImageFour"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field }) => (
                  <UploadField
                    id="car-image-four-feild"
                    title="خلفي"
                    subTitle="جانب الراكب"
                    setValue={setValue}
                    error={errors.carImageFour}
                    field={field}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3} className={classes.formRowShift}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              {/**  */}
              <Controller
                name="fristOwner"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field }) => (
                  <RadioField
                    label=" هل انت المالك الاول للسيارة؟ "
                    options={[
                      {
                        value: "yes",
                        label: "نعم",
                      },
                      {
                        value: "no",
                        label: "لا",
                      },
                    ]}
                    error={errors.fristOwner}
                    field={field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              {/**  */}
              <Controller
                name="hadAccident"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field }) => (
                  <RadioField
                    label=" هل تعرضت السيارة لأي حادث؟ "
                    options={[
                      {
                        value: "yes",
                        label: "نعم",
                      },
                      {
                        value: "no",
                        label: "لا",
                      },
                    ]}
                    error={errors.hadAccident}
                    field={field}
                  />
                )}
              />
            </Grid>

            {/** we will replace it with text editor */}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {/**  */}
              <Controller
                name="damage"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field }) => (
                  <TextFeild
                    label="إذا ، نعم: ما مقدار الضرر؟"
                    id="damage-feild"
                    placeholder="اكتب الاضرار الموجودة"
                    error={errors.damage}
                    field={field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              {/**  */}
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field }) => (
                  <TextFeild
                    label="اسم"
                    id="name-feild"
                    placeholder="اكتب الاسم"
                    error={errors.name}
                    field={field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              {/**  */}
              <Controller
                name="phone"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field }) => (
                  <TextFeild
                    label="هاتف"
                    id="phone-number-feild"
                    placeholder="ادخل رقم الهاتف"
                    error={errors.phone}
                    field={field}
                  />
                )}
              />
            </Grid>

            {/** * replace with Google Map location */}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {/**  */}
              <Controller
                name="location"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field }) => (
                  <TextFeild
                    label="موقع السيارة"
                    id="car-location-feild"
                    placeholder="عدد المسافات"
                    error={errors.location}
                    field={field}
                  />
                )}
              />
            </Grid>

            {/** * replace with text editor */}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {/** notes feild  */}
              <Controller
                name="notes"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field }) => (
                  <TextFeild
                    label="ملاحظة"
                    id="notes-feild"
                    placeholder="اكتب الملاحاظات اذا وجدت"
                    error={errors.notes}
                    field={field}
                  />
                )}
              />
            </Grid>
          </Grid>

          {/*end form */}
        </Grid>

        {/* form submitiion */}
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          style={{
            marginTop: 30,
            marginBottom: 30,
          }}
        >
          بيع سيارتي
        </Button>
      </form>
    </Box>
  )
}
